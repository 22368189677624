export default class Countdown {
    constructor(elemOrSelector, seconds) {
        this.elem = (typeof elemOrSelector === "string")
            ? document.querySelector(elemOrSelector)
            : elemOrSelector;
        this.seconds = seconds;
        this.isAttached = false;

        if (this.elem) {
            this.attach();
        }
    }

    attach() {
        if (this.isAttached) return;


        if (this.elem) {
            this.attachEvents();
            this.isAttached = true;
        }
    }

    attachEvents() {
        this.totalTime = this.seconds * 100;
        this.usedTime = 0;
        this.startTime = null;
        this.timer = null;
        this.start();
    }

    count() {
        this.usedTime = Math.floor((+new Date() - this.startTime) / 10);

        var tt = this.totalTime - this.usedTime;
        if (tt <= 0) {
            this.elem.innerHTML = '00:00.00';
            clearInterval(this.timer);
        } else {
            var mi = Math.floor(tt / (60 * 100));
            var ss = Math.floor((tt - mi * 60 * 100) / 100);
            var ms = tt - Math.floor(tt / 100) * 100;

            this.elem.innerHTML = this.fillZero(mi) + ":" + this.fillZero(ss) + "." + this.fillZero(ms);
        }
    }

    init() {
        if (this.timer) {
            clearInterval(this.timer);
            this.elem.innerHTML = '00:00.00';
            this.totalTime = this.seconds * 100;
            this.usedTime = 0;
            this.startTime = null;
            this.timer = null;
        }
    }

    start() {
        var storedStartTime = this.getCookie("countdownStartTime");
        if (storedStartTime) {
            this.totalTime = (this.seconds * 100)
            this.startTime = storedStartTime;
        } else {
            this.totalTime = this.seconds * 100;
            this.startTime = +new Date();
            var expires = (new Date(Date.now() + 3600000)).toUTCString(); //1 hour
            document.cookie = "countdownStartTime=" + this.startTime + ";expires=" + expires + ";path=/";
        }
        this.timer = setInterval(() => this.count(), 1);
    }

    stop() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    fillZero(num) {
        return num < 10 ? '0' + num : num;
    }

    getCookie(name) {
        var match = document.cookie.match(new RegExp(name + '=([^;]+)'));
        return match ? match[1] : null;
    }
}