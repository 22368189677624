import $ from 'jquery';

window.jQuery = $;
window.$ = $;

require('jsrender')($);     // Attach jsrender to jQuery
require('jquery.cookie');   // Attach jquery.cookie plugin to jQuery
!!$.cookie;                 // Ensure jquery.cookie is properly loaded


// Export it in case other modules need to use it explicitly
export default $;
