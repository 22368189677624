export var landingDescriptionbox

export var LandingPageLoad = function (input_placeholder_text = "Enter a description") {
    var selectiontext = ''

    Quill.register('modules/counter', function (box, options) {
        var container = document.querySelector(options.container);
        var hightlightcontainer = document.querySelector(options.highlightcontainer);


        box.on('text-change', function (delta) {


            hightlightcontainer.innerText = ''
            var text = box.getText().trim();

            if (text.length > 0) {
                var wordcount = (text.split(/\s+/).length)
                var wordcounttext = 'words'
                if (wordcount == 1) {
                    wordcounttext = 'word'
                }

                var characters = text.length

                if (options.limit !== null) {

                    var remainingcount = options.limit - characters
                    remainingcount = Math.abs(remainingcount)
                    var remainingtext = 'characters'
                    if (remainingcount === 1) {
                        remainingtext = 'character'
                    }
                    if (characters < options.limit / 2) {
                        container.innerText = '';
                        container.className = 'text-xs'
                    } else if (characters > options.limit) {
                        container.innerText = 'Max ' + options.limit + ' characters, please delete ' + remainingcount + ' ' + remainingtext;
                        container.className = 'text-xs text-danger mb-n2'
                    } else {
                        container.innerText = characters + '/' + options.limit + ' ' + remainingtext;
                        container.className = 'text-xs mb-n2'
                    }
                } else {
                    container.innerText = wordcount + ' ' + 'words';
                    container.className = 'text-xs mb-n2'
                }

            } else {
                container.innerText = ''
                container.className = 'text-xs mb-n2'
            }

            selectiontext = ''

        });
        box.on('selection-change', function (range, oldRange, source) {
            var btnoptions = document.querySelector('.ql-btncreate');
            if (range !== null && range.length > 0) {

                if (btnoptions) {
                    btnoptions.children[0].dataset.label = 'Instructions'
                }

                selectiontext = box.getText(range.index, range.length).trim();
                var wordcount = (selectiontext.split(/\s+/).length)
                var wordcounttext = 'words'
                if (wordcount === 1) {
                    wordcounttext = 'word'
                }
                var charactercounttext = 'characters'
                var characterscount = selectiontext.length
                if (characterscount === 1) {
                    charactercounttext = 'character'
                }

                hightlightcontainer.innerText = '(highlighted ' + wordcount + ' ' + wordcounttext + ' ' + characterscount + ' ' + charactercounttext + ')';
                hightlightcontainer.className = 'text-xs mb-n2'
            } else {
                hightlightcontainer.innerText = ''
                selectiontext = ''
                if (btnoptions) {
                    btnoptions.children[0].dataset.label = 'Highlight Text...'
                }
            }
        });
    });

    landingDescriptionbox = new Quill('#landing-description', {
        placeholder: input_placeholder_text,
        theme: 'snow', // Specify theme in configuration

        modules: {
            toolbar: false,
            counter: {
                container: '#counter',
                highlightcontainer: '#counterhighlight',
                limit: 4000
            }
        },
        formats: []
    });

    // var qlresult = document.getElementsByClassName("ql-editor");
    // qlresult[1].style.setProperty("max-height", '300px');


    if (document.getElementById('choices-button')) {
        var element = document.getElementById('choices-button');
        const dropdown = new Choices(element, {
            searchEnabled: false, // Disable the search input
            shouldSort: false,    // Prevent reordering of items (optional)
        });
    }


    var qlcontainer = document.getElementById("landing-description");
    qlcontainer.style.setProperty("border-top-left-radius", '0.5rem');
    qlcontainer.style.setProperty("border-top-right-radius", '0.5rem');

    var qlEditor = qlcontainer.querySelector('.ql-editor');
    qlEditor.style.setProperty("max-height", '300px');

}