import { DateTime } from 'luxon'

export default class FormatTimes {
  constructor(fromNow, shortDate, shortDateTime) {
    this.fromNowSelector = fromNow;
    this.shortDateSelector = shortDate;
    this.shortDateTimeSelector = shortDateTime;
    this.isAttached = false;
    this.attach();
  }

  attach() {
    if (this.isAttached) return;

    if ($(this.fromNowSelector).length === 0 && $(this.shortDateSelector).length === 0 && $(this.shortDateTimeSelector).length === 0) {
      return;
    }

    this.attachUI();
    this.attachEvents();
    this.isAttached = true;
  }

  attachUI() {
    this.fromNow = $(this.fromNowSelector);
    this.shortDate = $(this.shortDateSelector);
    this.shortDateTime = $(this.shortDateTimeSelector)
  }

  attachEvents() {
    this.fromNow.each(function (i, e) {
      (function updateTime() {
        let time = DateTime.fromISO($(e).data('datetime'));

        $(e).text(time.toRelative());
        $(e).attr('title', time.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS));

        setTimeout(updateTime, 1000);
      })();
    });

    this.shortDate.each(function (i, e) {
      let time = DateTime.fromISO($(e).data('datetime'));

      $(e).text(time.toLocaleString(DateTime.DATE_MED));
      $(e).attr('title', time.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS));
    });

    this.shortDateTime.each(function (i, e) {
      let time = DateTime.fromISO($(e).data('datetime'));

      $(e).text(time.toLocaleString(DateTime.DATETIME_MED));
      $(e).attr('title', time.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS));
    });

  }

  static addDateTimeNow() {
    return DateTime.fromMillis(Date.now()).toLocaleString(DateTime.DATETIME_MED);
  }
};

